"use client"
import mixpanel from 'mixpanel-browser';
import { type ProviderProps, useMemo } from 'react';
import { type AnalyticsContext, analyticsContext } from './contexts/AnalyticsContext';
import analytics from './utils/Analytics';

export interface AnalyticsProviderProps extends Omit<ProviderProps<AnalyticsContext>, 'value'> {
  token: string;
}

export function AnalyticsProvider({
  children,
  token,
}: AnalyticsProviderProps) {
  const config = useMemo(
    () => ({
      track_pageview: true,
    }),
    [],
  );

  const context = useMemo(
    () => {
      mixpanel.init(token, config);
      return analytics(mixpanel);
    },
    [config, token],
  );


  return <analyticsContext.Provider value={context}>{children}</analyticsContext.Provider>;
}