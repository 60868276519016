'use client';

import { useTranslation } from '@/app/i18n/client';
import { useEffect } from 'react';
import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { useAnalytics } from './hooks/useAnalytics';

declare global {
  interface Window {
    CookieConsentApi: any;
  }
}

export function isAnalyticsEnabled(): boolean {
  return (
    window.CookieConsentApi &&
    window.CookieConsentApi.getUserPreferences().accepted_categories.includes(
      'analytics',
    )
  );
}

export function InitCookieBanner() {
  const { t } = useTranslation();
  const analytics = useAnalytics();
  useEffect(() => {
    if (!document.getElementById('cc--main')) {
      window.CookieConsentApi = (window as any).initCookieConsent();           
        window.CookieConsentApi.run({
          autorun: true,
          current_lang: 'en',
          autoclear_cookies: true,
          page_scripts: true,

          onAccept: function (cookie: { categories: string[] }) {
            if (cookie.categories.includes('analytics')) {
              analytics && analytics.enableTracking();
            }
          },

          languages: {
            en: {
              consent_modal: {
                title: t('cookie-title'),
                description: t('cookie-description'),
                primary_btn: {
                  text: t('cookie-accept-button'),
                  role: 'accept_all', // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                  text: t('cookie-settings-button'),
                  role: 'settings', // 'settings' or 'accept_necessary'
                },
              },
              settings_modal: {
                title: t('cookie-modal-title'),
                save_settings_btn: t('cookie-modal-save-settings-button'),
                accept_all_btn: t('cookie-modal-accept-button'),
                reject_all_btn: t('cookie-modal-reject-button'), // optional, [v.2.5.0 +]
                cookie_table_headers: [
                  { col1: t('cookie-modal-table-name') },
                  { col2: t('cookie-modal-table-domain') },
                  { col3: t('cookie-modal-table-expiration') },
                  { col4: t('cookie-modal-table-description') },
                  { col5: t('cookie-modal-table-type') },
                ],
                blocks: [
                  {
                    title: t('cookie-modal-preferences-title'),
                    description: t('cookie-modal-preferences-description'),
                  },
                  {
                    title: t('cookie-modal-necessary-cookies-title'),
                    description: t('cookie-modal-necessary-cookies-description'),
                    toggle: {
                      value: 'necessary',
                      enabled: true,
                      readonly: true,
                    },
                  },
                  {
                    title: t('cookie-modal-analytics-cookies-title'),
                    description: t('cookie-modal-analytics-cookies-description'),
                    toggle: {
                      value: 'analytics',
                      enabled: false,
                      readonly: false,
                    },
                    cookie_table: [
                      {
                        col1: t('cookie-modal-table-name-google-ga'),
                        col2: t('cookie-modal-table-domain-google-ga'),
                        col3: t('cookie-modal-table-expiration-google-ga'),
                        col4: t('cookie-modal-table-description-google-ga'),
                        col5: t('cookie-modal-table-type-google-ga'),
                        is_regex: true,
                      },
                      {
                        col1: t('cookie-modal-table-name-google-gid'),
                        col2: t('cookie-modal-table-domain-google-gid'),
                        col3: t('cookie-modal-table-expiration-google-gid'),
                        col4: t('cookie-modal-table-description-google-gid'),
                        col5: t('cookie-modal-table-type-google-gid'),
                      },
                      {
                        col1: t('cookie-modal-table-name-mixpanel'),
                        col2: t('cookie-modal-table-domain-mixpanel'),
                        col3: t('cookie-modal-table-expiration-mixpanel'),
                        col4: t('cookie-modal-table-description-mixpanel'),
                        col5: t('cookie-modal-table-type-mixpanel'),
                        is_regex: true,
                      },
                    ],
                  },
                  {
                    title: t('cookie-modal-more-information-title'),
                    description: t('cookie-modal-more-information-description'),
                  },
                ],
              },
            },
          },
        });
      }
    }, []);
    return null;
  }
