const Arrow = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.53678 1L12 5.59491M12 5.59491L6.53678 10.2284M12 5.59491L0.673819 5.59492"
        stroke="currentColor"
        strokeWidth="1.6"
      />
    </svg>
  );
};

export default Arrow;
