'use client';

import { usePartner } from '@/lib/partners/usePartner/client';
import { useAnalytics } from '../Analytics';
import Arrow from './Arrow';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';

export interface ButtonProps {
  implementAsAnchor?: boolean;
  children: React.ReactNode;
  href?: string;
  includeArrow?: boolean;
  type?: 'button' | 'submit' | 'reset';
  id: string;
  mixpanelButtonText: string;
  onclick?: () => any;
  isLoading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  className?: string;
  testID?: string;
  secondaryButton?: boolean;
  successVariant?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

export const Button = ({
  implementAsAnchor,
  href,
  children,
  includeArrow,
  type,
  id,
  mixpanelButtonText,
  isLoading = false,
  loadingText = '',
  onclick,
  disabled = false,
  className,
  testID,
  secondaryButton = false,
  successVariant = false,
  target = '_self',
}: ButtonProps) => {
  const Tag = implementAsAnchor ? 'a' : 'button';
  const analytics = useAnalytics();
  const partner = usePartner();

  const partnerSettings = getPartnerSettings(partner);
  const prepopulatedJourney = partnerSettings?.prepopulatedJourney;

  if (isLoading && !disabled) {
    disabled = true;
  }

  // Nested turnary warning! Refactor required
  const disableBGColor = 'bg-buttonDisabledBackgroundColor';
  const BGColor = secondaryButton
    ? 'bg-core-8'
    : successVariant
      ? 'bg-success-2'
      : 'bg-buttonBackgroundColor';
  const disableTextColor = 'text-buttonDisabledTextColor/60';
  const buttonTextColor = secondaryButton
    ? 'text-core-1 secondaryBTN'
    : successVariant
      ? 'text-core-8'
      : 'text-buttonTextColor';
  const BGColorHover = secondaryButton
    ? 'hover:bg-core-1'
    : successVariant
      ? 'hover:bg-success-1'
      : 'hover:bg-buttonBackgroundColorHover';
  const buttonTextColorHover = secondaryButton
    ? 'hover:text-core-8'
    : successVariant
      ? 'hover:text-core-1'
      : 'hover:text-buttonTextColorHover';
  const borderColorHover = secondaryButton
    ? 'hover:border-core-8'
    : successVariant
      ? ''
      : 'hover:border-primary';

  const loadingAnimation = prepopulatedJourney
    ? 'overflow-hidden'
    : 'animate-pulse';
  let cssClasses = className;
  if (!className) {
    cssClasses = `${
      disabled && isLoading && `pointer-events-none ${loadingAnimation}`
    } relative group flex w-full ${disabled ? disableBGColor : BGColor} ${
      disabled
        ? `${disableTextColor} font-normal`
        : `${buttonTextColor} font-normal ${BGColorHover} ${buttonTextColorHover} ${borderColorHover}`
    }  leading-32 h-12 items-center rounded-full  whitespace-nowrap transition duration-250 ease-in-out border-2 ${
      successVariant ? 'border-success-1' : 'border-transparent'
    } `;
  }

  let anchorProps: { target?: string } = {};
  if (implementAsAnchor) {
    anchorProps['target'] = target;
  }

  return (
    <Tag
      href={href}
      className={cssClasses}
      type={type}
      onClick={() => {
        onclick?.();
        analytics?.trackButtonClick(partner, mixpanelButtonText, id);
      }}
      data-testid={testID}
      {...anchorProps}
    >
      <span className="flex-1 text-center">
        {loadingText && isLoading ? loadingText : children}
      </span>
      {includeArrow && !isLoading && (
        <div className="absolute right-0 transform transition-transform duration-250 group-hover:translate-x-3 mr-8">
          <Arrow />
        </div>
      )}
    </Tag>
  );
};
